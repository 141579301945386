
export default {
  name: 'MainPage',
  layout(context) {
    const hasPortfolioPageDisabled = context.store.state?.base?.meta?.generalConfig?.portfolioPageDisabled || false;
    return hasPortfolioPageDisabled ? "project" : "landing"
  },
  computed: {
    portfolioPageEnabled() {
      return !this.$store.state.base.meta.generalConfig.portfolioPageDisabled;
    }
  }
}
